import * as React from 'react';
import { graphql, HeadFC, PageProps, useStaticQuery } from 'gatsby';
import Layout from '../../layouts/Layout';
import SmallHero from '../../components/SmallHero/SmallHero';
import JobTable from '../../components/JobTable/JobTable';
import JobAlerts from '../../components/JobAlerts/JobAlerts';

const SearchPage: React.FC<PageProps> = () => {
	const data = useStaticQuery(graphql`
		query {
			allSitePage {
				edges {
					node {
						pageContext
						id
					}
				}
			}
		}
	`);
	const menuItems = [
		{
			text: 'Home',
			href: '/',
			active: false
		},
		{
			text: 'Positions',
			href: '/search/',
			active: false
		},
		{
			text: 'Our Story',
			href: '/story/',
			active: false
		},
		{
			text: 'Military',
			href: '/military/',
			active: false
		},
		{
			text: 'Benefits',
			href: '/benefits/',
			active: false
		},
		{
			text: 'Meet the Team',
			href: '/team/',
			active: false
		},
		{
			text: 'Career Path',
			href: '/career-path/',
			active: false
		}
	];
	return (
		<Layout
			menuItems={menuItems}
			title={'Belle Tire Careers'}
			desc={'Test'}
		>
			<SmallHero />
			<JobTable data={data} />
			<JobAlerts />
		</Layout>
	);
};

export default SearchPage;

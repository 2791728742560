import * as React from 'react';

interface SmallHeroProps {
	h1?: string;
	h2?: string;
}

const SmallHero: React.FC<SmallHeroProps> = (props: SmallHeroProps) => {
	return (
		<section className={'bg-bt-blue px-3'}>
			<div className={'container mx-auto py-16'}>
				<h1 className={'font-rubik text-5xl font-bold text-white'}>
					{props.h1 ? props.h1 : 'Job Listings'}
				</h1>
				{props.h2 ? (
					<h2
						className={
							'pt-18 mt-16 font-rubik text-[40px] font-normal text-white'
						}
					>
						{props.h2}
					</h2>
				) : (
					''
				)}
			</div>
		</section>
	);
};

export default SmallHero;
